import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'

import { Grid, Row, Col } from 'react-flexbox-grid'
import Image from 'gatsby-image'
import WorkTitleBlock from '../../components/WorkTitleBlock'
import FullPage from '../../components/FullPage'

export const frontmatter = {
  title: 'Vip Garant Logo',
  type: 'Identity',
  client: 'Vip Garant',
  path: '/works/identity/vip-garant-logo/',
  date: '2007-08-15',
  image: 'vip-garant-logo-demo',
  favorite: true,
}

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativePath: { regex: "^/vip-garant/i/" } }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
  <Layout location={location}>
    <Helmet title={frontmatter.title} />

    <FullPage align="top">
      <Grid fluid>
        <Row>
          <Col xs={12} sm={4}>
            <h1>{frontmatter.title}</h1>
            <p>
              Vip Garant &mdash; luxurios security for important persons and
              companies.
            </p>
          </Col>
          <Col xs={12} sm={1}>

          </Col>
          <Col xs={12} sm={5}>
            <figure className="text-center">
              <Image
                fluid={findImage(images, 'vip-garant-logo')}
                alt="Vip Garant Logo"
              />
            </figure>
          </Col>
        </Row>
      </Grid>
    </FullPage>

    <WorkTitleBlock {...frontmatter} />
  </Layout>
  )
}
